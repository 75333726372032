import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '../../providers/file.service';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { FileEvent, FileUploadResponse, ImagePickerConfig } from '../../types';
import { ToolsService } from '../../providers/tools.service';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  standalone: true,
  imports: [MatRipple, NgxFileDropModule, ImageCropperModule],
})
export class ImagePickerComponent implements OnInit {
  public photoText = 'Select Photo';
  public isInvalid: boolean;
  public croppedImage: string;
  public imageChangedEvent: FileEvent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImagePickerConfig,
    private toolsService: ToolsService,
    private fs: FileService,
    public dialogRef: MatDialogRef<ImagePickerComponent>
  ) {}

  public ngOnInit(): void {
    if (window.location.pathname.includes('companies')) {
      this.photoText = 'Upload Logo';
    }
  }

  public imageCropped(image: ImageCroppedEvent): void {
    this.croppedImage = image.base64;
  }

  public save = async (): Promise<void> => {
    if (this.data?.returnBlob) {
      this.dialogRef.close(this.b64toBlob(this.croppedImage, this.data.size));
      return;
    }
    this.toolsService.loading(true, 'Uploading...');
    const res: FileUploadResponse = await this.fs.uploadPhoto(
      this.b64toBlob(this.croppedImage, this.data.size),
      `${this.data.path}/${this.imageChangedEvent.target.files[0].name}`
    );
    this.toolsService.loading();
    res.url ? this.dialogRef.close(res.url) : null;
  };

  private b64toBlob(b64Data: string, sliceSize: number = 512): Blob {
    const str = b64Data.split(',')[1];
    const contentType = b64Data.split(',')[0].split(':')[1].split(';')[0];
    const byteCharacters = atob(str);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public dropped($event: NgxFileDropEntry[]): void {
    const files = [];
    for (let i = 0; i < $event.length; i++) {
      const droppedFile = $event[i];
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => files.push(file));
      }
    }
    setTimeout(() => (this.imageChangedEvent = { target: { files } }), 100);
  }
}
