import { Injectable } from '@angular/core';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { getApp } from 'firebase/app';
import { Attachment, FileUploadResponse } from '../types';

@Injectable()
export class FileService {
  fileUploading: any = false;
  fileUploadingStatus: any = 0;

  photoUploading: any = false;
  photoUploadingStatus: any = 0;

  storageRef = getStorage(getApp());

  constructor() {}

  uploadPhotos(file, url) {
    return new Promise((resolve: any) => {
      const blob = file;
      const metadata: any = {
        contentType: file.type,
      };
      const uploadRef = ref(this.storageRef, `${url}/${file.name}`);
      const uploadTask = uploadBytesResumable(uploadRef, blob, metadata);
      const onError = () => {
        resolve('');
      };
      const onComplete: any = () =>
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve({ url });
        });
      const onStateChange = () => null;
      uploadTask.on('state_changed', onStateChange, onError, onComplete);
    });
  }

  public uploadFile(data, url): Promise<Attachment> {
    return new Promise((resolve: any) => {
      this.fileUploading = true;
      const uploadRef = ref(this.storageRef, `${url}/${data.name}`);
      const uploadTask = uploadBytesResumable(uploadRef, data);
      const onError = (error) => {
        this.fileUploading = false;
        resolve({ error });
      };
      const onComplete: any = () =>
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          this.fileUploading = false;
          resolve({ url, name: data.name, createdAt: new Date() });
        });
      const onStateChange = (snapshot) => {
        this.fileUploadingStatus = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      };
      uploadTask.on('state_changed', onStateChange, onError, onComplete);
    });
  }

  public uploadPhoto(data, url): Promise<FileUploadResponse> {
    return new Promise((resolve: any) => {
      this.photoUploading = true;
      const uploadRef = ref(this.storageRef, url);
      const uploadTask = uploadBytesResumable(uploadRef, data);
      const onError = (error) => {
        this.photoUploading = false;
        resolve({ error });
      };
      const onComplete: any = () =>
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          this.photoUploading = false;
          resolve({ url });
        });
      const onStateChange = (snapshot) => {
        this.photoUploadingStatus = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      };
      uploadTask.on('state_changed', onStateChange, onError, onComplete);
    });
  }

  public deletePhoto = async (url): Promise<void> => {
    const desertRef = ref(this.storageRef, url);
    return deleteObject(desertRef);
  };

  public deleteFile = async (path): Promise<void> => {
    const desertRef = ref(this.storageRef, path);
    return deleteObject(desertRef);
  };
}
