<h3 class="prompt-title" matDialogTitle>{{ data.title }}</h3>
<mat-dialog-content class="color-primary prompt-dialog">
  <p [innerHTML]="data.message"></p>
  <mat-form-field class="prompt-input">
    @if (data.phoneMask) {
      <input
        [type]="data.type || 'text'"
        [(ngModel)]="result"
        matInput
        [placeholder]="data.placeholder"
        mask="(000) 000-0000"
      />
    }
    @if (!data.phoneMask) {
      <input [type]="data.type || 'text'" [(ngModel)]="result" matInput [placeholder]="data.placeholder" />
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-button color="primary" (click)="dialogRef.close(null)">{{ data.cancel || 'Cancel' }}</button>
  <button mat-button color="primary" (click)="dialogRef.close(result)" cdkFocusInitial>{{ data.ok || 'OK' }}</button>
</mat-dialog-actions>
