// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  FIREBASE_CREDENTIAL: {
    apiKey: 'AIzaSyBbfu-DjBtMGnyTy5jWP-ii59W3lAleHgQ',
    authDomain: 'colleen-bcca5.firebaseapp.com',
    projectId: 'colleen-bcca5',
    storageBucket: 'colleen-bcca5.appspot.com',
    messagingSenderId: '29231498384',
    appId: '1:29231498384:web:01f2c1c3c685756b1b8415',
    measurementId: 'G-198T5SGZKR',
  },
  EMAIL_SERVICE_BASE_URL: 'https://email-test.colleen.ai',
  NS_URL: 'https://api-test.colleen.ai',
  RECAPTCHA_SITE_KEY: '6LcJ_3IiAAAAAEjwQXnkmvlGO-hF_pPyA887GpLc',
  CONSOLE_URL: 'https://test.colleen.ai',
  LATEST_VERSION_NUMBER: 20220907,
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
